<template>
  <div>
    <welcome-conference-banner 
      :custom-css-background='backgroundGradientMiddle'
      :custom-image-css='customImageCss' />
    <div class='relative mt-12'>
      <content-background-cover
        class='z-0'
        :top-offset='0'
        :custom-css='backgroundGradientTop' />
      <sessions-currently-live
        tab-size='sm'
        title='' />
    </div>
    <welcome-sponsors-carousel class='mt-12'/>
    <section-links
      :section-links='sectionLinks'
      class='py-8 hidden' />
  </div>
</template>

<script>
import ContentBackgroundCover  from '@/components/ContentBackgroundCover.vue'
import SessionsCurrentlyLive   from '@/components/SessionsCurrentlyLive.vue'
import SectionLinks            from '@/components/SectionLinks.vue'
import WelcomeConferenceBanner from '@/components/welcome/WelcomeConferenceBanner.vue'
import WelcomeSponsorsCarousel from '@/components/welcome/WelcomeSponsorsCarousel.vue'

export default {
  name: 'Main',
  components: {
    ContentBackgroundCover,
    WelcomeConferenceBanner,
    SessionsCurrentlyLive,
    WelcomeSponsorsCarousel,
    SectionLinks,
  },
  computed: {
    backgroundGradientTop () {
      return 'background: linear-gradient(180deg, #E1E6E855 0%, #EFEFF0 100%);'
    },
    backgroundGradientMiddle () {
      return 'background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);'
    },
    sectionLinks () {
      return [
        {
          title:           'opening address',
          actionType:      'visit',
          actionString:    'visit',
          linkOrUrl:       'https://www.webconcert.co.kr',
          color:           '#1A0207',
          backgroundColor: '#FFFBFC'
        },
        {
          title:           'welcome message',
          actionType:      'visit',
          actionString:    'view',
          linkOrUrl:       'https://www.webconcert.co.kr',
          color:           '#1A0207',
          backgroundColor: '#FFFBFC'
        },
        {
          title:           'About KHRS',
          actionType:      'visit',
          actionString:    'learn more',
          linkOrUrl:       'https://www.webconcert.co.kr',
          color:           '#1A0207',
          backgroundColor: '#FFFBFC'
        },
        {
          title:           'Thanks you message',
          actionType:      'visit',
          actionString:    'read',
          linkOrUrl:       'https://www.webconcert.co.kr',
          color:           '#1A0207',
          backgroundColor: '#FFFBFC'
        },
      ]
    },
    customImageCss () {
      return 'min-height: 120px; object-fit: cover !important;'
    },
  },
}
</script>
